    <template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <v-card style="background-color: #fec5bb; border-radius: 30px; border: 2px solid white;">
                    <v-card-title>
                        <i class="menu-icon far fa-building icon-lg pr-5" style="color: black;"></i>
                        <span class="title font-weight-light text-black"><h4 class="mb-0">Laporan Nasabah</h4></span>
                    </v-card-title>
                </v-card>
            </div>
            <div class="col-md-8 text-right">
                <v-btn
                  class="mr-2"
                  color="#fec5bb"
                  style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                  @click="semua()"
                  >
                  <b>Semua</b>
                </v-btn>
                <v-btn
                  class="mr-2"
                  color="#fec5bb"
                  style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                  @click="aktif()"
                  >
                  <b>Aktif</b>
                </v-btn>
               <v-btn
                  color="#fec5bb"
                  style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                  @click="tidakAktif()"
                  >
                  <b>Tidak Aktif</b>
                </v-btn>
            </div>
        </div>
        <v-spacer></v-spacer>
        <div class="row">
            <div class="col-md-12">
                 <v-card>
                    <template>
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="dNasabah"
                            item-key="Id"
                            :options.sync="options"
                            :server-items-length="tdNasabah"
                            class="elevation-1"
                            :loading="loadingTable"
                            loading-text="Proses Data..."
                            :footer-props="{
                                'items-per-page-text':'Data Per Halaman',
                                'items-per-page-options': [5, 25, 50, 100]
                            }"
                        >
                        <template v-slot:[`item.number`]="{ index }">
                        <td align="center">{{(options.page * options.itemsPerPage) - options.itemsPerPage + (index + 1)}}</td>
                        </template>
                        <!-- <template v-slot:[`item.selisih`]="{ item }">
                            <span>Masa berlaku sudah</span>
                        </template> -->
                        <template v-slot:[`item.status`]="{ item }">
                          <v-chip
                            color="#a6808c"
                            dark
                          >
                            {{ item.status_fe }}
                          </v-chip>
                        </template>
                         <!-- <template v-slot:[`item.keterangan`]="{ item }">
                          <v-chip
                            :color="item.color"
                            dark
                          >
                            {{ item.keterangan }}
                          </v-chip>
                        </template> -->
                        <template v-slot:no-data>
                        </template>
                        </v-data-table>
                    </v-app>
                    </template>
                </v-card>
            </div>
        </div>
    </div>
</template>
<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>
<script>
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
// import Swal from "sweetalert2";
import storagelocal from "@/core/services/storagelocal.service";
// import VueHtml2pdf from 'vue-html2pdf'

  export default {
    components: {
        // VueHtml2pdf
    },
    props: {
    //   idheader: {
    //       type: String,
    //       required: false
    //   },
    //   accessList: {
    //     type: Array,
    //     required: false,
    //     default: () => []
    //   },
    },
    data(){ 
      return{
        baseUrlUpload: ApiService.getBaseUrlUpload(),
        headers: [
          {
            text: 'Id',
            // align: 'start',
            sortable: false,
            value: 'id',
            align: ' d-none'
          },
          { text: 'NO', value: 'number', align: 'center', sortable: false, class:'black--text text--darken-4' },
          { text: 'NAMA', value: 'nama', sortable: false, align: 'center', class:'black--text text--darken-4' },
          { text: 'BANK', value: 'bank', class:'black--text text--darken-4', sortable: false, },
          { text: 'CABANG', value: 'cabang', class:'black--text text--darken-4', sortable: false, },
          { text: 'CONTACT', value: 'contact', class:'black--text text--darken-4', sortable: false, },
        //   { text: 'KETERANGAN', value: 'keterangan', class:'black--text text--darken-4', sortable: false, },
        //   { text: 'MASA BERLAKU', value: 'tgl_akhir_akte_conv', class:'black--text text--darken-4', sortable: false, },
          { text: 'EMAIL', value: 'email', width: 30, align: 'left', class:'black--text text--darken-4', sortable: false, },
          { text: 'NO. HP', value: 'no_hp', width: 20, align: 'left', class:'black--text text--darken-4', sortable: false, },
          { text: 'STATUS', value: 'status', width: 20, align: 'left', class:'black--text text--darken-4', sortable: false, },
        ],
        options: {
          page: 1,
          itemsPerPage: 5,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: true,
          mustSort: false
        },
        dNasabah: [],
        tdNasabah: 0,
        loadingTable: false,
        isFirstLoad: false,
        flag: ''
      }
    }, 
    watch: {
        options: {
            async handler (val) {
                val
                if(!this.isFirstLoad)
                    await this.load2()
            },
            deep: true,
        },
    },
    methods : {
        async load2(){
            Promise.all([
                await this.getDataNasabah(),
            ])
            .then( (results)=> {
            this.isFirstLoad = false
            results
            })
        },
        converterDateTable(date){
            var convDate = new Date(date)
            var dd = String(convDate.getDate()).padStart(2, '0');
            var mm = String(convDate.getMonth() + 1).padStart(2, '0'); 
            var yyyy = convDate.getFullYear();
        
            return dd + '-' + mm + "-" + yyyy
        },
        getDataNasabah(){
            this.dNasabah = []
            return new Promise(resolve => {
            var mydata = {
                UID 		        : storagelocal.getLocalStorage('UID'),
                Token		        : storagelocal.getLocalStorage('token'),
                Trigger	            : 'R',
                options             : this.options,
                status              : this.flag,
                Route               : 'report_nasabah'
            }
            this.loadingTable = true
            Services.PostData(ApiService, "m_nasabah", mydata, async response=>{
                var responseCace = response.data
                for(let i=0; i<responseCace.length; i++){
                    // responseCace[i].tgl_akhir_akte_conv = this.converterDateTable(responseCace[i].tgl_akhir_akte)
                    // var b = Array.from(responseCace[i].selisih.toString());
                    // if(b[0] == "-" && responseCace[i].color == 'orange' ){
                    //     var a = responseCace[i].selisih.toString()
                    //     var c =  a.split("-")
                    //     responseCace[i].keterangan = "Masa berlaku sudah lewat " + c[1] + " hari"
                    // }else if(responseCace[i].color == 'green'){
                    //     responseCace[i].keterangan = "Masih berlaku"
                    // }else if(responseCace[i].color == 'blue'){
                    //     responseCace[i].keterangan = "Masa berlaku berakhir hari ini"
                    // }
                    if(responseCace[i].status == '0'){
                        responseCace[i].status_fe = 'Tidak Aktif'
                    }else{
                        responseCace[i].status_fe = 'Aktif'
                    }
                }
                this.dNasabah = responseCace
                this.tdNasabah = response.total
                resolve(this.dNasabah);
                this.loadingTable = false
                }, err =>{
                err
                })
            });
        },
        async tidakAktif(){
            this.flag = '0'
            await this.getDataNasabah()
        },
        async aktif(){
            this.flag = '1'
            await this.getDataNasabah()
        },
        async semua(){
            this.flag = ''
            await this.getDataNasabah()
        }
    }
  }
</script>
        